import Vue from 'vue'
import Papa from 'papaparse'
import EasyCamera from 'easy-vue-camera'
import _ from 'lodash'
Vue.use(EasyCamera)

let t = null

function sortArray (arr, key, desc) {
  if (typeof arr === 'object') {
    return arr.sort((a, b) => (a[key] > b[key]) ? (desc ? -1 : 1) : ((b[key] > a[key]) ? (desc ? 1 : -1) : 0))
  } else {
    return arr
  }
}

Vue.prototype.$ArrToObj = function (arr, key) {
  // return _.mapValues(_.groupBy(arr, key), clist => clist.map(arr => _.omit(arr, key)))
  return _.groupBy(arr, key)
}

Vue.prototype.$sortArray = function (arr, key, desc) {
  return sortArray(arr, key, desc)
}

Vue.filter('price', function (value) {
  if (!value) {
    return 'Rp 0'
  }
  value = parseInt(value).toLocaleString()
  return 'Rp ' + value
})

function ValidatedPriceCounter (type, v1, v2) {
  if (type === 'quantity') {
    if ((parseInt(v1) || 0) >= (parseInt(v2) || 0)) {
      return true
    }
  } else if (type === 'customer') {
    if (isNaN(parseInt(v2))) {
      return true
    } else if (parseInt(v2) === parseInt(v1)) {
      return true
    }
  } else if (type === 'platform') {
    if (isNaN(parseInt(v2))) {
      return true
    } else if (parseInt(v2) === parseInt(v1)) {
      return true
    }
  }
  return false
}

Vue.prototype.$priceCounter = function (pbj, price, quantity, customerType, platform) {
  price = parseInt(price)
  let priceAdded = 0
  let data = null
  const priceBerjenjang = pbj ? sortArray(pbj, 'minimum_quantity', true) : []
  for (let c = 0; c < priceBerjenjang.length; c++) {
    const pb = priceBerjenjang[c]
    if (ValidatedPriceCounter('quantity', quantity, pb.minimum_quantity) && ValidatedPriceCounter('customer', customerType, pb.customer) && ValidatedPriceCounter('platform', platform, pb.platform)) {
      if (!data) {
        data = Object.assign({}, pb)
        priceAdded = parseInt(pb.discount) || 0
      }
    }
  }
  return {
    data: data,
    price: (price - priceAdded) || price
  }
}

Vue.prototype.$CART_PARSE = function (cart) {
  let amountSubtotal = 0
  let amountTax = 0
  let weight = 0
  for (let c = 0; c < cart.length; c++) {
    amountSubtotal += (parseFloat(cart[c].priceActive) * parseInt(cart[c].quantity))
    weight += (parseFloat(cart[c].weight) * parseInt(cart[c].quantity))
  }
  amountTax += (amountSubtotal * (10 / 100))
  return {
    amountSubtotal,
    amountTax,
    weight
  }
}

Vue.prototype.$SHIPPING_COST = function (weight, pricePerKg) {
  let kgs = ((weight || 1) / 1000)
  if ((kgs % 1) > 0.2) {
    kgs = Math.ceil(kgs)
  } else {
    kgs = Math.floor(kgs)
  }
  if (kgs < 1) {
    kgs = 1
  }
  return parseFloat(pricePerKg || 0) * kgs
}

Vue.prototype.$TrxStatus = function (status) {
  const st = {
    name: 'Undefined',
    color: 'red',
    icon: 'mdi-minus'
  }
  switch (parseInt(status)) {
    case 0:
      st.name = 'Menunggu Pembayaran'
      st.color = 'orange'
      st.icon = 'mdi-av-timer'
      break
    case 1:
      st.name = 'Diproses'
      st.color = 'green'
      st.icon = 'mdi-package-variant'
      break
    case 2:
      st.name = 'Siap Dikirim'
      st.color = 'blue'
      st.icon = 'mdi-clipboard-text-clock-outline'
      break
    case 3:
      st.name = 'Dikirim'
      st.color = 'purple'
      st.icon = 'mdi-truck-fast-outline'
      break
    case 4:
      st.name = 'Selesai'
      st.color = 'indigo'
      st.icon = 'mdi-store-check'
      break
    case 99:
      st.name = 'Dibatalkan'
      st.color = 'red darken-2'
      st.icon = 'mdi-table-cancel'
      break
    case 100:
      st.name = 'Dihapus'
      st.color = 'red'
      st.icon = 'mdi-close-thick'
      break
    default:
      st.name = 'Undefined'
      st.color = 'grey'
      st.icon = 'mdi-information-off-outline'
      break
  }
  // 0: PENDING, 1: PROCESSING, 2: READY TO SHIP, 3: SHIPPED, 4: DELIVERED/COMPLETE, 99: Canceled/Expired, 100: Deleted
  return st
}

Vue.prototype.$total = function (cart) {
  let total = 0
  if (cart) {
    if (cart.length) {
      for (let c = 0; c < cart.length; c++) {
        const priceItem = parseInt(cart[c].priceActive)
        total += (priceItem * (parseInt(cart[c].quantity)) || 1)
      }
    }
  }
  return total
}

Vue.prototype.$price = function (price, sign) {
  if (!price) {
    return (sign || 'Rp') + (price)
  }
  price = sign === 'USD' ? parseFloat(price).toFixed(2) : parseInt(price).toLocaleString()
  return (sign || 'Rp') + price
}

Vue.prototype.$ConvWeight = function (weight, decCount, short) {
  if (parseInt(weight)) {
    weight = parseInt(weight)
    if (weight < 1000) {
      return weight + (short ? 'g' : ' grams')
    } else if ((weight / 1000) < 1000) {
      return (weight / 1000).toFixed(decCount || 0) + (short ? 'Kg' : ' Kilogram')
    } else {
      return ((weight / 1000) / 1000).toFixed(decCount || 0) + (short ? 'Ton' : ' Ton')
    }
  } else {
    return (0).toFixed(decCount || 0) + ((short ? 'Kg' : ' Kilogram'))
  }
}

Vue.prototype.$NumOnly = function (e) {
  const key = e.keyCode ? e.keyCode : e.which
  if (isNaN(String.fromCharCode(key)) && key !== 8 && key !== 46 && key !== 37 && key !== 39) {
    e.preventDefault()
    return false
  }
}

Vue.prototype.$validateName = function (name) {
  return /^[A-Za-z\s]+$/.test(name)
}

Vue.prototype.$validateEmail = function (email) {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return email ? re.test(email) : false
}

Vue.prototype.$validatePhone = function (phone, arePhone) {
  if (phone) {
    if (phone.length > 7 && phone.length < 15) {
      if (arePhone === '0' && phone.length > 9 && phone.length < 14) {
        if (phone.substring(0, 2) === '08') {
          return true
        } else {
          return false
        }
      } else if (arePhone) {
        if (phone.substring(0, 1) === '2' || phone.substring(0, 2) === '02' || phone.substring(0, 2) === '62' || phone.substring(0, 3) === '+62') {
          return true
        } else {
          return false
        }
      } else if (phone.substring(0, 1) === '8' || phone.substring(0, 2) === '08' || phone.substring(0, 3) === '628' || phone.substring(0, 4) === '+628') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

Vue.prototype.$reformatPhone = function (phone) {
  if (phone) {
    if (phone.substring(0, 1) === '8') {
      return '62' + phone
    } else if (phone.substring(0, 2) === '08') {
      return '62' + phone.substring(1, phone.length)
    } else if (phone.substring(0, 2) === '62') {
      return phone
    } else if (phone.substring(0, 3) === '+62') {
      return phone.substring(1, phone.length)
    } else {
      return null
    }
  } else {
    return null
  }
}

Vue.prototype.$moneyInt = function (amount) {
  if (amount && typeof amount === 'string') {
    return parseInt(amount) ? parseInt(amount.split('.')[0].split(',').join('')) : 0
  } else {
    return 0
  }
}

Vue.prototype.$money = function (amount, decimalCount = 2, decimal = '.', thousands = ',') {
  if (amount && typeof amount === 'string') {
    amount = parseInt(amount) ? parseInt(amount.split('.')[0].split(',').join('')) : 0
  } else {
    amount = 0
  }
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount
    const negativeSign = amount < 0 ? '-' : ''

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    const j = (i.length > 3) ? i.length % 3 : 0

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
  } catch (e) {
    console.log(e)
    return 0
  }
}

Vue.prototype.$timeToDecimal = function (t, p) {
  const arr = t.split(':')
  const dec = parseInt((arr[1] / 6) * 10, 10)
  return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec).toFixed(parseInt(p) ? parseInt(p) : 1)
}

Vue.prototype.$decimalToTime = function (minutes) {
  const sign = minutes < 0 ? '-' : ''
  const min = Math.floor(Math.abs(minutes))
  const sec = Math.floor((Math.abs(minutes) * 60) % 60)
  return sign + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec
}

Vue.prototype.$getTimeLocal = function (utcDate) {
  const localDate = new Date(utcDate)
  const hour = localDate.getHours()
  const minutes = localDate.getMinutes()
  return (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
}

Vue.prototype.$getThisWeek = function (dt, isList, fromModay) {
  const curr = dt ? new Date(dt) : new Date()
  const currMonth = curr.getMonth()
  const first = curr.getDate() - (fromModay ? (curr.getDay() - 1) : curr.getDay())
  const last = first + 6

  if (isList) {
    const dates = []
    for (let d = 0; d < 7; d++) {
      curr.setMonth(currMonth)
      const curr2 = new Date(curr.setDate(first + d))
      const dd = this.$localDT(curr2, 'datedefault')
      dates.push(dd)
    }
    return dates
  } else {
    const firstday = new Date(curr.setDate(first)).toUTCString()
    const lastday = new Date(curr.setDate(last)).toUTCString()
    // if (first < 0) {
    //   lastday.setMonth(currMonth)
    // }
    return {
      from: firstday,
      to: lastday
    }
  }
}

Vue.prototype.$getThisMonth = function (dtcustom) {
  const formattedDate = function (date) {
    const m = ('0' + (date.getMonth() + 1)).slice(-2)
    const d = ('0' + date.getDate()).slice(-2)
    const y = date.getFullYear()
    return y + '-' + m + '-' + d
  }
  const currDate = dtcustom ? new Date(dtcustom) : new Date()
  const firstDay = new Date(currDate.getFullYear(), currDate.getMonth(), 1)
  const lastDay = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0)
  const monthStartDate = formattedDate(firstDay)
  const monthEndDate = formattedDate(lastDay)

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  return {
    from: monthStartDate,
    to: monthEndDate,
    lastDateDay: lastDay.getDate(),
    lastDateDayName: dayNames[lastDay.getDay()],
    lastDateMonth: lastDay.getMonth() + 1,
    lastDateYear: lastDay.getFullYear()
  }
}

Vue.prototype.$thisMonth = function (dt) {
  const now = dt ? (new Date(dt)) : (new Date())
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
  return {
    first: firstDay,
    last: lastDay
  }
}

Vue.prototype.$localDT = function (utcDate, type) {
  const localDate = utcDate ? new Date(utcDate) : new Date()
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const dayNamesLocal = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const monthNamesLocal = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
  // const year = localDate.getFullYear().toString().substr(-2)
  const year = localDate.getFullYear().toString()
  const month = monthNames[localDate.getMonth()]
  const day = localDate.getDate()
  const dayName = dayNames[localDate.getDay()]
  const dayNameLocal = dayNamesLocal[localDate.getDay()]
  const monthNameLocal = monthNamesLocal[localDate.getMonth()]

  /* eslint-disable-next-line */
  const seconds = localDate.getSeconds()
  const minutes = localDate.getMinutes()
  const hour = localDate.getHours()

  if (type === 'sajian') {
    return dayNameLocal + ', ' + (day < 10 ? '0' + day : day) + ' ' + monthNameLocal + ' ' + year
  } else if (type === 'daydate') {
    return dayName + ', ' + (day < 10 ? '0' + day : day) + ' ' + month + ' ' + year
  } else if (type === 'monthname') {
    return month
  } else if (type === 'monthnamelocal') {
    return monthNameLocal
  } else if (type === 'number') {
    return (day < 10 ? '0' + day : day) + '' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '' + year.substr(2, 2)
  } else if (type === 'date') {
    return dayName + ' ' + month + ', ' + year
  } else if (type === 'display') {
    return month + ' ' + (day < 10 ? '0' + day : day) + ', ' + year
  } else if (type === 'datelocal') {
    return (day < 10 ? '0' + day : day) + '/' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '/' + year
  } else if (type === 'datetimelocal') {
    return (day < 10 ? '0' + day : day) + '/' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '/' + year + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else if (type === 'monthyear') {
    return month + ', ' + year
  } else if (type === 'datedefault') {
    return year + '-' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '-' + (day < 10 ? '0' + day : day)
  } else if (type === 'datetimedefault') {
    return year + '-' + (localDate.getMonth() + 1) + '-' + day + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else if (type === 'time') {
    return (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else if (type === 'datetime') {
    return dayName + ', ' + day + ' ' + month + ' ' + year + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else {
    return localDate
  }
}

Vue.prototype.$diffTimes = function (dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= (60 * 60)
  return parseFloat(diff).toFixed(2)
}

Vue.prototype.$getThisYear = function () {
  const curr = new Date()
  const year = curr.getFullYear()

  const firstyear = new Date(year + '-01-01').toUTCString()
  const lastyear = new Date(year + '-12-31').toUTCString()
  return {
    from: firstyear,
    to: lastyear
  }
}

Vue.prototype.$getImage = function (featured, index) {
  try {
    const newGalleries = JSON.parse(featured)
    if (newGalleries.length) {
      return newGalleries[index || 0]
    } else {
      return '/images/no-image.png'
    }
  } catch {
    return '/images/no-image.png'
  }
}

Vue.prototype.$getImageAll = function (galleriesString) {
  try {
    const newGalleries = JSON.parse(galleriesString)
    if (newGalleries.length) {
      return newGalleries
    } else {
      return ['/images/no-image.png']
    }
  } catch {
    return ['/images/no-image.png']
  }
}

Vue.prototype.$filetoBase64 = function (event, callback) {
  /* eslint-disable */
  function getType(ext) {
    if (ext === 'mov' || ext === 'mp4' || ext === 'avi' || ext === 'flv') {
      return 'video'
    } else if (ext === 'doc' || ext === 'docx' || ext === 'ppt' || ext === 'pptx' || ext === 'xls' || ext === 'xlsx' || ext === 'csv' || ext === 'txt' || ext === 'pdf' || ext === 'psd') {
      return 'doc'
    } else if (ext === 'jpg' || ext === 'jpeg' || ext === 'gif' || ext === 'png' || ext === 'svg') {
      return 'photo'
    } else if (ext === 'mp3' || ext === 'wav') {
      return 'audio'
    } else if (ext === 'csv') {
      return 'csv'
    } else {
      return 'unknown'
    }
  }
  let r = {
    status: false,
    ext: '',
    type: '',
    name: '',
    data: null
  }
  let f = event.target.files || event.dataTransfer.files

  const reader = new FileReader()
  if (f[0]) {
    const fname = event.target.files[0].name
    const lastDot = fname.lastIndexOf('.')
    r.ext = fname.substring(lastDot + 1)
    r.type = getType(r.ext)
    r.name = fname || ''

    const fSize = r.ext === 'mov' || r.ext === 'mp4' || r.ext === 'avi' || r.ext === 'flv' ? 20000000 : 1000000 // 10MB : 1MB

    if (f[0].size <= fSize) {
      reader.readAsDataURL(f[0])
      reader.onload = function (ev) {
        r.status = true
        r.data = ev.target.result
        callback(r)
      }
      reader.onerror = function (error) {
        r.status = false
        r.data = error
        callback(r)
      }
    } else {
      r.status = false
      r.data = 'file_size'
      callback(r)
    }
  } else {
    r.status = false
    r.data = 'canceled'
    callback(r)
  }
}

Vue.prototype.$displayWeight = function (weight, decCount, short) {
  if (parseInt(weight)) {
    weight = parseInt(weight)
    if (weight < 1000) {
      return weight + (short ? 'g' : ' gram')
    } else if ((weight / 1000) < 100) {
      return (weight / 1000).toFixed(decCount || 0) + (short ? 'Kg' : ' Kilogram')
    } else if ((weight / 1000) < 1000) {
      return ((weight / 1000) / 100).toFixed(decCount || 0) + (short ? 'Q' : ' Quintal')
    } else {
      return ((weight / 1000) / 1000).toFixed(decCount || 0) + (short ? 'Ton' : ' Ton')
    }
  } else {
    return 0
  }
}

Vue.prototype.$convToGrams = function (weight, unit) {
  if (unit === 'ton') {
    return parseInt(weight) * 1000000
  } else if (unit === 'quintal') {
    return parseInt(weight) * 100000
  } else if (unit === 'kg') {
    return parseInt(weight) * 1000
  } else {
    return parseInt(weight) || 0 // grams
  }
}

Vue.prototype.$role = function (roles, role) {
  if (role === 'root' || role === 'admin') {
    return true
  } else {
    return roles ? roles.find(item => item === role) : false
  }
}

Vue.prototype.$TodayDate = function () {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  const todaydate = yyyy + '-' + mm + '-' + dd
  return todaydate
}

Vue.prototype.$DateReformat = function (dt) {
  const ndate = new Date(dt)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const year = ndate.getFullYear().toString().substr(-2)
  const month = monthNames[ndate.getMonth()]
  const day = ndate.getDate()
  return day + ' ' + month + ' ' + year
}

Vue.prototype.$DateRangeDays = function (fromd, tod) {
  const from = new Date(fromd)
  const to = new Date(tod)
  return parseInt(((from - to) / 86400000))
}

Vue.prototype.$DateDiffDays = function (dt, days) {
  const date = new Date(dt)
  date.setDate(date.getDate() - days)
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()
  const ddt = yyyy + '-' + mm + '-' + dd
  return ddt
}

Vue.prototype.$DateAddDays = function (dt, days) {
  const date = new Date(dt)
  date.setDate(date.getDate() + days)
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()
  const ddt = yyyy + '-' + mm + '-' + dd
  return ddt
}

Vue.prototype.$NumberOnly = function (e) {
  const key = e.keyCode ? e.keyCode : e.which
  if (isNaN(String.fromCharCode(key)) && key !== 8 && key !== 46 && key !== 37 && key !== 39 && key !== 190) {
    e.preventDefault()
    return false
  }
}

Vue.prototype.$AlphaNumeric = function (str) {
  if (str) {
    str = str.replace(/[\W_]+/g, "").toUpperCase()
  }
  return str || ''
}

Vue.prototype.$JsonToStr = function (json) {
  if (json) {
    try {
      json = JSON.stringify(json)
    } catch {
      json = ''
    }
    return json
  }
  return ''
}

Vue.prototype.$StrToJson = function (str) {
  if (str) {
    try {
      str = JSON.parse(str)
    } catch {
      str = {}
    }
    return str
  }
  return {}
}

Vue.prototype.$ArrayToStr = function (json) {
  if (json) {
    try {
      json = JSON.stringify(json)
    } catch {
      // json = json
    }
    return json
  }
  return ''
}

Vue.prototype.$StrToArray = function (str) {
  if (str) {
    if (typeof str === 'string') {
      try {
        str = JSON.parse(str)
      } catch {
        str = []
      }
      return str
    } else if (str) {
      return str
    } else {
      str = []
    }
  }
  return []
}

Vue.prototype.$CSV = async function (file) {
  return await new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: (res) => {
        resolve(res)
      }
    })
  })
}

Vue.prototype.$Location = async function (_this) {
  if (!t && _this) {
    t = _this
  }
  // chrome://settings/content
  return new Promise(resolve => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError)
      navigator.geolocation.watchPosition(watchPosition)
      // navigator.geolocation.clearWatch()
    } else {
      resolve({
        err: true,
        data: 'Geolocation is not supported by this browser.'
      })
    }

    function watchPosition (position) {
      t.$store.dispatch('ME_UPDATE', {
        pos_lat: position.coords.latitude + '',
        pos_lng: position.coords.longitude + ''
      })
    }

    function showPosition (position) {
      resolve({
        err: false,
        data: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    }

    function showError (error) {
      let msg = ''
      switch(error.code) {
        case error.PERMISSION_DENIED:
          msg = 'User denied the request for Geolocation.'
          break
        case error.POSITION_UNAVAILABLE:
          msg = 'Location information is unavailable.'
          break
        case error.TIMEOUT:
          msg = 'The request to get user location timed out.'
          break
        case error.UNKNOWN_ERROR:
          msg = 'An unknown error occurred.'
          break
      }
      resolve({
        err: true,
        data: msg
      })
    }

  })
}
