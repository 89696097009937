<template>
  <v-app>
    <Toast />
    <Auth v-if="!$store.state.user" />
    <v-main v-else class="grey lighten-4">
      <div class="main-appbar white">
        <v-row no-gutters>
          <v-col
            :class="[ColorStoreActive + ' darken-2']"
            cols="4"
            class="d-flex align-center justify-center font-weight-bold c-pointer pa-0 lh-0 col-sm-2"
          >
            <div v-if="$store.state.storeData.length">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex align-center justify-center fill-height"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="d-inline-block caption lh-0 white--text font-weight-black">
                      {{ $store.state.storeActive ? $store.state.storeData.filter(r => r.id === $store.state.storeActive)[0].name : 'Select Store' }}
                    </span>
                    <v-icon small class="ml-2" color="white">
                      mdi-chevron-down
                    </v-icon>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item v-for="(s, iS) in (($store.state.user.role !== 'root' && $store.state.user.role !== 'admin') ? $store.state.storeData.filter(r => parseInt(r.id) === parseInt($store.state.user.store)) : $store.state.storeData)" :key="'store-' + iS" @click.prevent="$store.commit('STORE_ACTIVE', s.id)">
                    <v-list-item-title>
                      <v-icon v-if="parseInt(s.id) === $store.state.storeActive" small class="mr-2" color="primary">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else small class="mr-2">
                        mdi-map-marker
                      </v-icon>
                      {{ s.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              No Store
            </div>
          </v-col>
          <v-col cols="8" class="col-sm-10">
            <v-tabs
              fixed-tabs
              :height="48"
              :background-color="ColorStoreActive"
              dark
            >
              <v-tabs-slider :color="ColorStoreActive + ' darken-2'" />
              <v-tab to="/" class="text-capitalize ls-05">
                <v-icon small>
                  mdi-home
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Home
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager'], $store.state.user.role)" to="/schedules" class="text-capitalize ls-05">
                <v-icon small>
                  mdi-calendar-clock
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Schedules
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager'], $store.state.user.role)" to="/inventory" class="text-capitalize ls-05">
                <v-icon small>
                  mdi-silverware
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  All Menu
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager'], $store.state.user.role)" to="/transaction" class="text-capitalize ls-05">
                <v-icon small>
                  mdi-cash-register
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Transaction
                </span>
              </v-tab>
              <v-tab
                v-if="false"
                class="text-capitalize ls-05"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="d-flex align-center justify-center fill-height"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>
                        mdi-plus
                      </v-icon>
                      <span class="hidden-sm-and-down ml-2">
                        Features
                      </span>
                    </div>
                  </template>
                  <v-list dense>
                    <v-list-item to="/features/voucher">
                      <v-list-item-title>
                        <v-icon small class="mr-2">
                          mdi-ticket
                        </v-icon>
                        Voucher
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/features/tour-guide">
                      <v-list-item-title>
                        <v-icon small class="mr-2">
                          mdi-car
                        </v-icon>
                        Tour Guide (Selfie)
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/features/customer-point">
                      <v-list-item-title>
                        <v-icon small class="mr-2">
                          mdi-star
                        </v-icon>
                        Customer Point
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/features/main-banner">
                      <v-list-item-title>
                        <v-icon small class="mr-2">
                          mdi-image-multiple-outline
                        </v-icon>
                        Media
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-tab>
              <v-tab
                class="text-capitalize ls-05"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="d-flex align-center justify-center fill-height"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>
                        mdi-plus
                      </v-icon>
                      <span class="hidden-sm-and-down ml-2">
                        More
                      </span>
                    </div>
                  </template>
                  <v-list dense>
                    <v-list-item to="/customer">
                      <v-list-item-title>
                        <v-icon small class="mr-2">
                          mdi-account-group
                        </v-icon>
                        Customer
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$role(['manager'], $store.state.user.role)" to="/account-manager">
                      <v-list-item-title>
                        <v-icon small class="mr-2">
                          mdi-account-cog
                        </v-icon>
                        Account Manager
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$role(['manager'], $store.state.user.role)"  to="/store-manager">
                      <v-list-item-title>
                        <v-icon small class="mr-2">
                          mdi-store-cog
                        </v-icon>
                        Store Manager
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.prevent="DO_LOGOUT">
                      <v-list-item-title>
                        <v-icon small class="mr-2" color="red">
                          mdi-power
                        </v-icon>
                        Sign Out
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </div>
      <div class="main-container">
        <v-container fluid>
          <router-view/>
        </v-container>
      </div>
    </v-main>
    <Map />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    ColorStoreActive () {
      const storeActive = parseInt(this.$store.state.storeActive)
      let color = 'grey'
      if (storeActive) {
        const store = this.$store.state.storeData.find(r => parseInt(r.id) === storeActive)
        if (store) {
          if (store.color) {
            return store.color
          }
        }
        const index = this.$store.state.storeData.findIndex(r => parseInt(r.id) === storeActive)
        switch (index) {
          case 0:
            color = 'primary'
            break
          case 1:
            color = 'blue'
            break
          case 2:
            color = 'purple'
            break
          case 3:
            color = 'green'
            break
          case 4:
            color = 'teal'
            break
          case 5:
            color = 'lime darken-1'
            break
          default:
            break
        }
      }
      return color
    }
  },
  methods: {
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
    }
  }
}
</script>

<style lang="scss">
.main-appbar {
  position: relative;
  height: 48px!important;
}
.main-container {
  height: calc(100% - 48px);
  position: relative;
  overflow-y: auto;
}
.c-pointer {
  cursor: pointer!important;
}
.fill-width {
  width: 100%!important;
}
.ls-0 {
  letter-spacing: 0px!important;
}
.ls-05 {
  letter-spacing: .5px!important;
}
.ls-1 {
  letter-spacing: 1px!important;
}
.lh-0 {
  line-height: 100%!important;
}
a {
  text-decoration: none!important;
  color: unset!important;
}
.v-card {
  border-radius: 0px!important;
}
.file-uploader {
  width: 0px;
  height: 0px;
  position: fixed;
  bottom: -100%;
  z-index: -2;
}
.v-tabs {
  .v-slide-group__prev,
  .v-slide-next {
    display: none!important;
  }
}
.theme--dark {
  &.btn-checkout {
    &.v-btn--disabled {
      &.v-btn--has-bg {
        &.v-btn {
          background-color: rgba(0, 0, 0, .1)!important;
          color: rgba(0, 0, 0, .3)!important;
        }
      }
    }
  }
}
.caption {
  letter-spacing: 0px!important;
}
.caption-small {
  font-size: 12px!important;
  letter-spacing: 0px;
}
.v-textarea {
  textarea {
    line-height: 125%!important;
  }
}
a {
  &.disabled {
    pointer-events: none!important;
  }
}
</style>
